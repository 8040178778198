export const SERVER_URL = `${process.env.REACT_APP_API_ENDPOINT}/auth`;
export const SERVER_BASE_URL = process.env.REACT_APP_API_ENDPOINT;
export const ERROR_MESSAGE = "Api Fetch Error!";
export const ENCRYPT_SECERET_KEY = "armia-secret-key@123";
export const REACT_APP_API_POST_USER = `${process.env.REACT_APP_API_ENDPOINT}/user`;
export const REACT_APP_API_FETCH_USER = `${process.env.REACT_APP_API_ENDPOINT}/user?`;
export const REACT_APP_API_FETCH_EMPLOYEE = `${process.env.REACT_APP_API_ENDPOINT}/employee?active=true&`;
export const REACT_APP_API_FETCH_ROLE = `${process.env.REACT_APP_API_ENDPOINT}/roles?active=true&`;
export const REACT_APP_API_SKILLS = `${process.env.REACT_APP_API_ENDPOINT}/dummy/selectdata`;
export const REACT_APP_API_ROLES_DROPDOWN = `${process.env.REACT_APP_API_ENDPOINT}/roles/select`;
