/* eslint-disable react/react-in-jsx-scope */
import { useState } from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  StaticRouter as Static,
} from "react-router-dom";
import store from "./redux/store";

import "./App.css";

import Routers from "./router/index";
import "bootstrap/dist/css/bootstrap.min.css";
import TableContextProvider from "./contexts/tableContext";
import { getCookies, setCookies } from "./hooks/useCookies";

function App() {
  const [showBanner, setShowBanner] = useState(false);
  const cookieBannerControl = () => {
    setShowBanner(!showBanner);
    setCookies("showCookieBanner", false);
  };
  const canUseDOM = !!(
    typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
  );
  if (canUseDOM) {
    return (
      <Provider store={store}>
        <div className="App">
          <TableContextProvider>
            <Router>
              <Routers />
            </Router>
          </TableContextProvider>
        </div>
        {showBanner && getCookies("showCookieBanner") !== "false" ? (
          <div className="cookie-banner">
            <p>This website uses cookies to enhance user experience.</p>
            <button
              type="button"
              className="close"
              onClick={cookieBannerControl}
            >
              I Understand
            </button>
          </div>
        ) : null}
      </Provider>
    );
  }
  return (
    <Provider store={store}>
      <div className="App">
        <TableContextProvider>
          <Static>
            <Routers />
          </Static>
        </TableContextProvider>
      </div>
      {showBanner && getCookies("showCookieBanner") !== "false" ? (
        <div className="cookie-banner">
          <p>This website uses cookies to enhance user experience.</p>
          <button type="button" className="close" onClick={cookieBannerControl}>
            I Understand
          </button>
        </div>
      ) : null}
    </Provider>
  );
}
export default App;
