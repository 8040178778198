import React, { useContext, useEffect } from "react";
// import { Container, Row, Col, Button } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { TableContext } from "../../contexts/tableContext";
// import bannercircle from "../../assets/bannercircle.png";
// import banner1top from "../../assets/banner1top.png";
// import banner1shade from "../../assets/banner1shade.png";
// import banner2 from "../../assets/banner2.png";
// import banner3 from "../../assets/banner3.svg";
// import banner4 from "../../assets/banner4.png";
// import banner4top from "../../assets/banner4top.png";
// import banner5 from "../../assets/banner5.png";
// import banner5top from "../../assets/banner5top.png";
// import underconstruction from "../../assets/under_construction.jpg";
import styles from "./landing.module.scss";

function Landing() {
  const canUseDOM = !!(
    typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
  );
  const { setHeaderColor } = canUseDOM ? useContext(TableContext) : "navlink";
  useEffect(() => {
    if (canUseDOM) {
      setHeaderColor("navlink");
    }
  });
  return (
    <>
      <section>
        <div className={styles.construction_img}>
          <h2>Under Construction</h2>
          <p>Powered By :  www.dreemly.com</p>
        </div>
      </section>
      {/* <section className={styles.banner1section}>
        <Container>
        <Row>
            <Col lg={6} md={6} sm={12}>
              <div className={styles.textcontent}>
                <h2 className={styles.top_header}>
                  Social Media, On Your Terms
                </h2>
                <p>
                  Ready for something fresh and exciting? On Dreemly, you are in
                  complete control. Live. Love. Earn. Dreemly. It&apos;s Yours
                  to Discover.
                </p>

                <Button
                  type="button"
                  className="btn btn-primary"
                  id={styles.getbutton}
                >
                  Get Started
                </Button>
              </div>
              <img
                className={styles.banner1shade}
                src={banner1shade}
                alt="banner"
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div className={styles.bannerimg}>
                <img
                  className={styles.bannercircle}
                  src={bannercircle}
                  alt="logo"
                />
                <img
                  className={styles.banner1top}
                  src={banner1top}
                  alt="banner"
                />
              </div>
            </Col>
          </Row>

        </Container>
      </section>

      <section className={styles.banner2section}>
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <img className={styles.banner2} src={banner2} alt="banner" />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div className={styles.textcontent}>
                <h2>Register to be Notified when we go LIVE</h2>
                <p>
                  We will send you a notification to alert you when we go live!
                </p>

                <Button
                  type="button"
                  className="btn btn-primary"
                  id={styles.getbutton}
                >
                  Register
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={styles.banner3section}>
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <div className={styles.textcontent}>
                <h2>More of what you love</h2>
                <p>
                  Discounts, savings and good times! Connect with the people,
                  places and things you love!
                </p>
                {canUseDOM ? (
                  <Link to="/" className={styles.learn_more}>
                    Register
                    <FontAwesomeIcon
                      icon={faLongArrowAltRight}
                      className={styles.rightarrow}
                    />
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <img className={styles.banner3} src={banner3} alt="banner" />
            </Col>
          </Row>
        </Container>
      </section>

      <section className={styles.banner4section}>
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <img className={styles.banner4} src={banner4} alt="banner" />
              <img
                className={styles.banner4top}
                src={banner4top}
                alt="banner"
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div className={styles.textcontent}>
                <h2>Love freebies ?</h2>
                <p>
                  Now is your chance to <span>win Free prizes.</span>
                  <br />
                  Hundreds of <span>Online Contests</span> and thousands of{" "}
                  <span>Giveaways</span>. You could be our next{" "}
                  <span>Winner!!!</span>
                </p>
                {canUseDOM ? (
                  <Link to="/" className={styles.learn_more}>
                    LEARN MORE
                    <FontAwesomeIcon
                      icon={faLongArrowAltRight}
                      className={styles.rightarrow}
                    />
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={styles.banner5section}>
        <Container>
          <Row>
            <div className="col-md-6">
              <div className={styles.textcontent}>
                <h2>
                  FaveShows: Where Fascinating and Unexpected Conversations
                  happen!
                </h2>
                <p>
                  Join discussions with hundreds of users who are keen about the
                  topic your favourite shows. Free online FaveShows where you
                  can chat with friends, meet new people and more.
                </p>
                {canUseDOM ? (
                  <Link to="/" className={styles.learn_more}>
                    LEARN MORE
                    <FontAwesomeIcon
                      icon={faLongArrowAltRight}
                      className={styles.rightarrow}
                    />
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <img className={styles.banner5} src={banner5} alt="banner" />
                <img
                  className={styles.banner5top}
                  src={banner5top}
                  alt="banner"
                />
              </div>
            </div>
          </Row>
        </Container>
      </section> */}
    </>
  );
}

export default Landing;
