import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Outerlayout from "../layouts/outerLayout";
import Landing from "../pages/landing/landing";

// const Layout = Outerlayout;

function Routers() {
  return (
    <Router>
      <Switch>
        <Route path="/">
            <Route exact path="/">
              {" "}
              <Landing />
            </Route>
        </Route>
      </Switch>
    </Router>
  );
}

export default Routers;
